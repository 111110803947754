import React from "react";
import { useTranslation } from "react-i18next";

import { readState } from "@/__main__/app-state.mjs";
import WeaponsStatsTable from "@/game-fortnite/components/WeaponsStatsTable";
import { WEAPON_CATEGORIES } from "@/game-fortnite/constants/weaponCategories.mjs";
import type { WeaponItem } from "@/game-fortnite/models/items.mjs";
import Container from "@/shared/ContentContainer.jsx";
import { useSnapshot } from "@/util/use-snapshot.mjs";

const ActiveWeapons = () => {
  const { t } = useTranslation();
  const {
    fortnite: { active },
  } = useSnapshot(readState);

  const isLoaded = Object.keys(active || {}).length > 0;

  return (
    <Container className="flex column gap-sp-8">
      {Object.keys(active || {}).map((category) => {
        const symbol = Object.getOwnPropertySymbols(WEAPON_CATEGORIES).find(
          (s) => WEAPON_CATEGORIES[s].key === category,
        );
        const weaponCategory = WEAPON_CATEGORIES[symbol];
        const weapons = Object.values(active[category] || {}) as WeaponItem[];
        return (
          <div key={category} className="flex column justify-center gap-8">
            <p className="type-title--bold">
              {t(
                ...(weaponCategory?.t ||
                  (["fortnite:category", "Category"] as Translation)),
              )}
            </p>
            <WeaponsStatsTable weapons={isLoaded ? weapons : null} />
          </div>
        );
      })}
    </Container>
  );
};

export default ActiveWeapons;
