import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { css } from "goober";
import { Select } from "clutch/src/Select/Select";
import { mobile } from "clutch/src/Style/style.mjs";
import { TextInput } from "clutch/src/TextInput/TextInput";

import { readState } from "@/__main__/app-state.mjs";
import { MIN_STRING_DISTANCE } from "@/app/constants.mjs";
import WeaponsStatsTable from "@/game-fortnite/components/WeaponsStatsTable.jsx";
import {
  WEAPON_CATEGORIES,
  WEAPON_CATEGORIES_SYMBOLS,
} from "@/game-fortnite/constants/weaponCategories.mjs";
import type { WeaponItem } from "@/game-fortnite/models/items.mjs";
import Container from "@/shared/ContentContainer.jsx";
import { useQuery } from "@/util/router-hooks.mjs";
import stringCompare from "@/util/string-compare.mjs";
import { useSnapshot } from "@/util/use-snapshot.mjs";

const searchContainer = () => css`
  display: flex;
  flex-direction: row;
  gap: var(--sp-2);

  ${mobile} {
    flex-direction: column;

    .selectButton {
      width: 100%;
    }
  }
`;

const categoryOptions = Object.getOwnPropertySymbols(WEAPON_CATEGORIES).reduce(
  (acc, i) => {
    const { key, t } = WEAPON_CATEGORIES[i];
    acc.push({ value: key, text: t });
    return acc;
  },
  [],
);

const DEFAULT_CATEGORY =
  WEAPON_CATEGORIES[WEAPON_CATEGORIES_SYMBOLS.fortniteAllWeapons].key;

const VaultedWeapons = () => {
  const { t } = useTranslation();
  const {
    fortnite: { vaulted },
  } = useSnapshot(readState);

  const [categoryFilter, setCategoryFilter] = useQuery<string>(
    "category",
    DEFAULT_CATEGORY,
  );
  const [nameFilter, setNameFilter] = useQuery<string>("name", "");
  const isLoaded = Object.keys(vaulted).length > 0;

  const filteredWeapons: WeaponItem[] | null = useMemo(() => {
    if (!isLoaded) return null;
    let filteredWeapons =
      categoryFilter !== DEFAULT_CATEGORY
        ? Object.values(vaulted[categoryFilter] || {})
        : Object.values(vaulted)
            .map((v) => Object.values(v))
            .flat(1);

    const filters = [
      nameFilter &&
        ((weapon) =>
          stringCompare(nameFilter, weapon.displayName) > MIN_STRING_DISTANCE),
    ];
    filters.forEach((filter) => {
      if (!filter) return;
      filteredWeapons = filteredWeapons.filter(filter);
    });

    return filteredWeapons as WeaponItem[];
  }, [categoryFilter, nameFilter, vaulted]);

  return (
    <Container className="flex column gap-sp-8">
      <div className={searchContainer()}>
        <TextInput
          placeholder={t("common:search", "Search")}
          onChange={(e) => setNameFilter(e.target.value)}
          value={nameFilter}
        />
        <Select
          options={categoryOptions}
          selected={categoryFilter || ""}
          onChange={(v) => setCategoryFilter(v || undefined)}
        />
      </div>
      <WeaponsStatsTable weapons={filteredWeapons} />
    </Container>
  );
};

export default VaultedWeapons;
