import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";

import { IS_APP } from "@/__main__/constants.mjs";
import ActiveWeapons from "@/game-fortnite/components/ActiveWeapons.jsx";
// import CompareWeapons from "@/game-fortnite/components/CompareWeapons.jsx";
import VaultedWeapons from "@/game-fortnite/components/VaultedWeapons.jsx";
import PageContainer from "@/shared/PageContainer";
import PageHeader from "@/shared/PageHeader";
import { useRoute } from "@/util/router-hooks.mjs";

const TABS = {
  active: {
    component: <ActiveWeapons />,
    url: "/fortnite/database/weapons/active",
    title: ["fortnite:database.weapons.active", "Active"] as Translation,
  },
  vaulted: {
    component: <VaultedWeapons />,
    url: "/fortnite/database/weapons/vaulted",
    title: ["fortnite:database.weapons.vaulted", "Vaulted"] as Translation,
  },
  // compare: {
  //   component: <CompareWeapons />,
  //   url: "/fortnite/database/weapons/compare",
  //   title: ["fortnite:database.weapons.compare", "Compare"] as Translation,
  // },
};

const DatabaseWeapons = () => {
  const { t } = useTranslation();
  const links = useMemo(
    () =>
      Object.values(TABS).map((tab) => ({
        url: tab.url,
        text: t(...tab.title),
      })),
    [t],
  );

  const {
    parameters: [tab],
  } = useRoute();

  const currTab = TABS[tab];

  return (
    <PageContainer>
      <PageHeader
        title={t(
          "fortnite:database.weapons.title",
          "Fortnite Weapons Database",
        )}
        links={links}
      />
      {currTab?.component}
    </PageContainer>
  );
};

export function meta() {
  return {
    title: [
      "fortnite:meta.database.weapons.title",
      "Fortnite Weapons Database",
    ] as Translation,
    description: [
      "fortnite:meta.database.weapons.description",
      "Explore the most comprehensive Fortnite Weapons Database on Blitz.gg! Find up-to-date stats, tier lists, and guides for all active Fortnite weapons to enhance your gameplay.",
    ] as Translation,
    subtitle: !IS_APP,
  };
}

export default DatabaseWeapons;
